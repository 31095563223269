@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Antic&family=Kanit:wght@200;500&family=Lato:wght@100;300;400&family=Poppins:wght@100;300;400;500&family=Roboto+Serif:opsz,wght@8..144,300;8..144,400;8..144,500;8..144,600&display=swap");

* {
  font-family: "Roboto Serif", sans-serif;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 7px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #1d2346ee;
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #02132e;
  border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1c345c;
}

.bg-video {
  background-image: url("./assets/files/comacon.gif");
}
